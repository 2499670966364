exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-cbsegrade-js": () => import("./../../../src/pages/cbsegrade.js" /* webpackChunkName: "component---src-pages-cbsegrade-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fandpassessment-js": () => import("./../../../src/pages/fandpassessment.js" /* webpackChunkName: "component---src-pages-fandpassessment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-nweamap-js": () => import("./../../../src/pages/nweamap.js" /* webpackChunkName: "component---src-pages-nweamap-js" */),
  "component---src-pages-ourteam-js": () => import("./../../../src/pages/ourteam.js" /* webpackChunkName: "component---src-pages-ourteam-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-whydarwin-js": () => import("./../../../src/pages/whydarwin.js" /* webpackChunkName: "component---src-pages-whydarwin-js" */),
  "component---src-pages-widaassessement-js": () => import("./../../../src/pages/widaassessement.js" /* webpackChunkName: "component---src-pages-widaassessement-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

